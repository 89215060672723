// import Aakar from "./aakar.ttf";
import aakar from "./aakar.ttf";
import shruti from "./shruti.ttf";
import Rasa from "./Rasa.ttf";
import Shrikhand from "./Shrikhand-Regular.ttf";
import MuktaVaaniRegular from "./MuktaVaani-Regular.ttf";
import BalooBhai2Regular from "./BalooBhai2-Regular.ttf";
import NotoSansGujarati from "./NotoSansGujarati.ttf";
import NotoSerifGujarati from "./NotoSerifGujarati.ttf";
import MartelRegular from "./Martel-Regular.ttf";
import Baloo2 from "./Baloo2-VariableFont_wght.ttf";
import MartelSansRegular from "./MartelSans-Regular.ttf";
import IBMPlexSansDevanagari from "./IBMPlexSansDevanagari-Regular.ttf";
import TimesNewRoman from "./timesNewRoman.ttf";
import NotoSansDevanagari from "./NotoSansDevanagari-VariableFont_wdth,wght.ttf";
import AmikoRegular from "./Amiko-Regular.ttf";
import AnnapurnaSILRegular from "./AnnapurnaSIL-Regular.ttf";
import TiroDevanagariHindiRegular from "./TiroDevanagariHindi-Regular.ttf";
import TiroDevanagariSanskritRegular from "./TiroDevanagariSanskrit-Regular.ttf";

export const fontStyleOptions = [
  {
    value: "Aakar",
    label: "આકાર",
    file: aakar,
    style: { fontFamily: "aakar" },
  },
  {
    value: "Shruti",
    label: "શ્રુતિ",
    file: shruti,
    style: { fontFamily: "shruti" },
  },
  // {
  //   value: "TimesNewRoman",
  //   label: "Times New Roman",
  //   file: TimesNewRoman,
  //   style: { fontFamily: "TimesNewRoman" },
  // },
  {
    value: "Rasa",
    label: "Rasa",
    file: Rasa,
    style: { fontFamily: "Rasa" },
  },
  {
    value: "Shrikhand",
    label: "Shrikhand",
    file: Shrikhand,
    style: { fontFamily: "shrikhandRegular" },
  },
  {
    value: "MuktaVaani-Regular",
    label: "મુક્ત વાણી",
    file: MuktaVaaniRegular,
    style: { fontFamily: "muktaVaaniRegular" },
  },
  {
    value: "Baloo_Bhai_2",
    label: "Baloo Bhai 2",
    file: BalooBhai2Regular,
    style: { fontFamily: "BalooBhai2Regular" },
  },
  {
    value: "NotoSansGujarati",
    label: "Noto Sans Gujarati",
    file: NotoSansGujarati,
    style: { fontFamily: "NotoSansGujarati" },
  },
  {
    value: "NotoSerifGujarati",
    label: "Noto Serif Gujarati",
    file: NotoSerifGujarati,
    style: { fontFamily: "NotoSerifGujarati" },
  },
  {
    value: "TimesNewRoman",
    label: "Times New Roman",
    file: TimesNewRoman,
    style: { fontFamily: "TimesNewRoman" },
  },
  {
    value: "MartelRegular",
    label: "Martel",
    file: MartelRegular,
    style: { fontFamily: "MartelRegular" },
  },
  {
    value: "Baloo2",
    label: "Baloo 2",
    file: Baloo2,
    style: { fontFamily: "Baloo2" },
  },
  {
    value: "MartelSansRegular",
    label: "Martel Sans",
    file: MartelSansRegular,
    style: { fontFamily: "MartelSansRegular" },
  },
  {
    value: "IBMPlexSansDevanagari",
    label: "IBM Plex Sans Devanagari",
    file: IBMPlexSansDevanagari,
    style: { fontFamily: "IBMPlexSansDevanagari" },
  },
  {
    value: "NotoSansDevanagari",
    label: "Noto Sans Devanagari",
    file: NotoSansDevanagari,
    style: { fontFamily: "NotoSansDevanagari" },
  },
  {
    value: "AmikoRegular",
    label: "Amiko",
    file: AmikoRegular,
    style: { fontFamily: "AmikoRegular" },
  },
  {
    value: "AnnapurnaSILRegular",
    label: "Annapurna SIL",
    file: AnnapurnaSILRegular,
    style: { fontFamily: "AnnapurnaSILRegular" },
  },
  {
    value: "TiroDevanagariHindiRegular",
    label: "Tiro Devanagari Hindi",
    file: TiroDevanagariHindiRegular,
    style: { fontFamily: "TiroDevanagariHindiRegular" },
  },
  {
    value: "TiroDevanagariSanskritRegular",
    label: "Tiro Devanagari Sanskrit",
    file: TiroDevanagariSanskritRegular,
    style: { fontFamily: "TiroDevanagariSanskritRegular" },
  },
  
];
