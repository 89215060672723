import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import Papa from "papaparse";
import ReusableTable from "../../reusableComponent/ReusableTable";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import { Image } from "react-bootstrap";
import { fetchCountry } from "../../../../store/eventsSlice";
import Select from "react-select";

const InsertCsv = ({
  csvKeys,
  setCsvKeys,
  tableData,
  setTableData,
  formData,
  setFormData,
  formError,
  setFormError,
  inputdata,
  validation,
  manualText,
  setManualText,
  textAreaData,
  setTextAreaData,
  validateStep,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [importMethod, setImportMethod] = useState("manual");
  const [step, setStep] = useState(1);
  const [finalTableData, setFinalTableData] = useState([]);
  const [originalTableData, setOriginalTableData] = useState([]);
  const [duplicateTableData, setDuplicateTableData] = useState([]);
  const [tableKeys, setTableKeys] = useState([]);
  const [isAllowDuplicate, setIsAllowDuplicate] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isFileImportDisabled, setIsFileImportDisabled] = useState(false);
  const [isManualImportDisabled, setIsManualImportDisabled] = useState(false);
  const [isNextButtonVisible, setIsNextButtonVisible] = useState(false); // State for showing Next button
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true); // State for enabling/disabling Next button
  const [inputChanged, setInputChanged] = useState(false); // State to track if input is changed

  console.log("FINAL TABLE DATA :::::::::::::::: ", finalTableData);

  console.log(inputChanged);

  const credits = useSelector((state) => state.auth.user.credits);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const closeModal = () => {
    // handleClearList();
    console.log("Table Data : ", tableData);
    if (!tableData.length) {
      handleClearList();
    }
    setModalOpen(!modalOpen);
  };

  const dispatch = useDispatch();

  const [countryCodes, setCountryCodes] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");

  console.log(selectedCountryCode);

  useEffect(() => {
    // Fetch country codes when component mounts
    dispatch(fetchCountry())
      .then((res) => {
        setCountryCodes(
          res.payload.map((country) => ({
            value: country.phone_code,
            label: `${country.name} (+${country.phone_code})`,
          }))
        );
      })
      .catch(() => {
        setErrorMessage("Failed to fetch country codes.");
      });
  }, [dispatch]);

  useEffect(() => {
    // Disable the Next button if country code is not selected on step 3
    if (step === 3 && !formData.countryCode) {
      setIsNextButtonDisabled(true);
    } else {
      setIsNextButtonDisabled(false);
    }
  }, [formData.countryCode, step]);

  const handleImportMethodClick = (method) => {
    if (method === "manual") {
      setIsFileImportDisabled(true);
      setIsManualImportDisabled(false);
    } else if (method === "file") {
      setIsManualImportDisabled(true);
      setIsFileImportDisabled(false);
    }
    setImportMethod(method);
    setStep(2);
    setIsNextButtonVisible(false); // Initially hide the Next button
    setInputChanged(false); // Reset input change tracking
    toggleModal();
  };

  const handleNextStep = () => {
    // Validate country code on step 3
    if (step === 3 && !formData.countryCode) {
      setErrorMessage("Country code is required.");
      setIsNextButtonDisabled(true);
      return;
    }
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const downloadSampleExcel = () => {
    const headers = ["Name", "Number", "Var1", "Var2"];
    const worksheet = XLSX.utils.json_to_sheet([{}], { header: headers });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sample");
    XLSX.writeFile(workbook, "SampleTemplate.xlsx");
  };

  const handleChipClick = (phoneCode) => {
    setSelectedCountryCode(phoneCode);
    setFormData({ ...formData, countryCode: phoneCode });
  };

  const renderImportNumbers = () => (
    <Fragment>
      <h4>Import Numbers</h4>
      {importMethod === "manual" ? (
        <div className="position-relative floating-label-content">
          <label className="floating-label">Enter Contact</label>
          <Input
            type="textarea"
            value={manualText}
            className="manual-textarea w-100 bg-white fs-16 floating-textarea"
            onChange={handleManualChange}
            rows={5}
            placeholder=""
            autoFocus
          />
          <Row className="d-flex justify-content-between align-items-center pt-2 pb-4 info_text">
            <Col lg="9">
              <p>
                <small>
                  <span className="add_info">
                    <Image
                      src="/images/modal-icon/info-icon.svg"
                      alt="info-icon"
                      className="me-1"
                      width={15}
                      height={15}
                    />
                    You can add Name then comma and then Mobile like
                  </span>
                  <b> (Name, Number,...)</b>
                </small>
              </p>
            </Col>
            <Col lg="3" className="d-flex justify-content-end">
              <Button
                color="primary"
                onClick={showManualDataInTable}
                className="mx-25 d-flex align-items-center"
              >
                <Image
                  src="/images/modal-icon/eye-icon.svg"
                  alt="eye-icon"
                  width={20}
                  height={20}
                  className="me-1"
                />
                Table View
              </Button>
            </Col>
          </Row>
        </div>
      ) : (
        <div>
          <Input type="file" accept=".csv" onChange={uploadCsv} />
          <Button color="link" onClick={downloadSampleExcel}>
            Download Sample File
          </Button>
        </div>
      )}
      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
      {isNextButtonVisible && finalTableData.length > 0 && (
        <ReusableTable
          tableData={finalTableData}
          tableKey={tableKeys}
          tableScrollClass="table table-bordered"
        />
      )}
    </Fragment>
  );

  const renderAddCountryCode = () => (
    <Fragment>
      <h4>Add Country Code</h4>
      <div className="mb-3">
        <Button
          color="primary"
          className="me-2"
          onClick={() => handleChipClick("91")} // India
        >
          India (+91)
        </Button>
        <Button
          color="primary"
          className="me-2"
          onClick={() => handleChipClick("1")} // USA/Canada
        >
          USA/Canada (+1)
        </Button>
        <Button
          color="primary"
          className="me-2"
          onClick={() => handleChipClick("44")} // UK (just as an example)
        >
          UK (+44)
        </Button>
      </div>
      <Select
        options={countryCodes}
        value={countryCodes.find(
          (option) => option.value === formData?.countryCode
        )}
        onChange={(selectedOption) => {
          setSelectedCountryCode(selectedOption.value);
          setFormData({ ...formData, countryCode: selectedOption.value });
        }}
        placeholder="Select Country Code"
      />
      <p className="text-danger">{formError.countryCode}</p>
    </Fragment>
  );

  const renderReviewDuplicates = () => (
    <Fragment>
      <h4>Review Duplicates</h4>
      <ReusableTable
        tableData={finalTableData}
        tableKey={tableKeys}
        tableScrollClass="table table-bordered"
      />
      <label for="allow-duplicates" className="form-check-label me-2">
        Allow Duplicates
      </label>
      <Input
        type="checkbox"
        id="allow-duplicates"
        checked={isAllowDuplicate}
        onChange={(e) => setIsAllowDuplicate(e.target.checked)}
      />
      <div className="d-flex">
        <ul className="d-flex mb-0 align-items-center p-0 total_data">
          <li className="list-unstyled">
            <p className="m-auto">
              Total:
              <span className="ms-1">{originalTableData.length}</span>
            </p>
          </li>
          <li className="list-unstyled ms-4">
            <p className="m-auto">
              Duplicate:
              <span className="ms-1">{duplicateTableData.length}</span>
            </p>
          </li>
        </ul>
      </div>
    </Fragment>
  );

  const handleManualChange = (e) => {
    setManualText(e.target.value);
    setTextAreaData(e.target.value.split("\n"));
    setIsNextButtonVisible(false); // Hide Next button when user modifies data
    setInputChanged(true); // Track that input was changed
  };

  const showManualDataInTable = () => {
    const newTableData = myNewObj(textAreaData);

    const hasInvalidData = newTableData.some((item) => !item.number || !item.name.trim());
    // const hasInvalidData = newTableData.some((item) => !item.name);
    if (hasInvalidData) {
      setErrorMessage("All rows must have a valid name or number.");
      setIsNextButtonDisabled(true); // Disable Next button if validation fails
      return;
    } else {
      setErrorMessage("");
      setIsNextButtonDisabled(false); // Enable Next button if validation passes
      setIsNextButtonVisible(true); // Show the Next button after successful validation
    }

    if (newTableData.length > credits) {
      setErrorMessage(`You can only add up to ${credits} guests.`);
    } else {
      setOriginalTableData(newTableData);
      setFinalTableData(newTableData);
      findDuplicate(newTableData, "number");
      setErrorMessage("");
    }
    setInputChanged(false); // Reset input change tracking after Table View is clicked
  };

  const myNewObj = (obj) => {
    const newRes = [];
    obj.forEach((item, index) => {
      if (item) {
        const newItem = item.split(",");
        let dataObject = {};
        let varCounter = 1;
        newItem.forEach((item, i) => {
          if (i === 0) {
            dataObject = { ...dataObject, name: item };
          } else if (i === 1) {
            dataObject = { ...dataObject, number: item };
          } else {
            dataObject = { ...dataObject, [`var${varCounter}`]: item };
            varCounter++;
          }
        });
        newRes.push(dataObject);
        if (index === 0) {
          const keys = Object.keys(dataObject);
          setTableKeys(keys);
        }
      }
    });
    return newRes;
  };

  const uploadCsv = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setErrorMessage("Please select a file.");
      return;
    }

    try {
      Papa.parse(file, {
        complete: function (results) {
          const csvFileData = parseCsvData(results.data);
          if (csvFileData.length > credits) {
            setErrorMessage(`You can only add up to ${credits} guests.`);
          } else {
            setOriginalTableData(csvFileData);
            setFinalTableData(csvFileData);
            findDuplicate(csvFileData, "number");
            setFormData({ ...formData, countryCode: "" });
            setTableKeys(Object.keys(csvFileData[0]));
            setErrorMessage("");
            setIsNextButtonVisible(true); // Show the Next button after successful file upload
            setIsNextButtonDisabled(false); // Enable Next button after file upload
          }
        },
        error: () => {
          setErrorMessage(
            "Error parsing CSV file. Please ensure it is formatted correctly."
          );
        },
      });
    } catch (error) {
      setErrorMessage("An unexpected error occurred while uploading the file.");
    }
  };

  const parseCsvData = (data) => {
    const newDataArray = data.map((item) => item.toString());
    let firstRow = newDataArray[0].split(",");
    if (
      firstRow[0].toLowerCase() === "name" &&
      firstRow[1].toLowerCase() === "number"
    ) {
      newDataArray.shift();
    }
    return newDataArray.map((item) => {
      const [name, number, ...vars] = item.split(",");
      return {
        name,
        number,
        ...vars.reduce((acc, v, i) => ({ ...acc, [`var${i + 1}`]: v }), {}),
      };
    });
  };

  const findDuplicate = (arrayOfObj, key) => {
    try {
      const grouped = groupBy(arrayOfObj, key);
      const output = [];
      const duplicateArray = [];

      Object.keys(grouped).forEach((key) => {
        const out = grouped[key].reduce((acc, current) => {
          duplicateArray.push(acc);
          return { ...current };
        });
        output.push(out);
      });

      setDuplicateTableData(duplicateArray);
      return output;
    } catch (error) {
      setErrorMessage("Error finding duplicates.");
    }
  };

  const groupBy = (arr, property) => {
    return arr.reduce((memo, x) => {
      if (!memo[x[property]]) {
        memo[x[property]] = [];
      }
      memo[x[property]].push(x);
      return memo;
    }, {});
  };

  const handleClearList = () => {
    setOriginalTableData([]);
    setFinalTableData([]);
    setDuplicateTableData([]);
    setTableData([]);
    setCsvKeys([]);
    setTableKeys([]);
    setManualText("");
    setTextAreaData([]);
    setFormData({ ...formData, countryCode: "" });
    setErrorMessage("");
    setIsAllowDuplicate(false);
    setIsFileImportDisabled(false); // Enable the file import method
    setIsManualImportDisabled(false); // Enable the manual import method
    setSelectedCountryCode("");
    setIsNextButtonVisible(false); // Hide the Next button on clear
    setIsNextButtonDisabled(true); // Disable Next button on clear
  };

  const handleEditClick = () => {
    if (importMethod === "manual") {
      setStep(2); // Set the step to manual import
    } else if (importMethod === "file") {
      setStep(2); // Set the step to file import
    }
    toggleModal(); // Open the modal
  };

  const renderCurrentStep = () => {
    switch (step) {
      case 2:
        return renderImportNumbers();
      case 3:
        return renderAddCountryCode();
      case 4:
        return renderReviewDuplicates();
      default:
        return null;
    }
  };

  return (
    <Fragment>
      <h4 className="m-0 text-start import_filedata">
        Import CSV File Or Manual Data
      </h4>
      <Row>
        <Col
          xl="12"
          className="d-flex align-items-center justify-content-between mb-4"
        >
          {tableData.length === 0 && (
            <div>
              <Button
                color="primary"
                onClick={() => handleImportMethodClick("manual")}
                disabled={isManualImportDisabled}
              >
                Manual Import
              </Button>
              <Button
                color="primary"
                className="ms-2"
                onClick={() => handleImportMethodClick("file")}
                disabled={isFileImportDisabled}
              >
                Import from File
              </Button>
            </div>
          )}
          {tableData.length !== 0 && (
            <div className="">
              <Button
                color="warning"
                className="me-2"
                disabled={tableData.length === 0}
                onClick={handleEditClick}
              >
                Edit
              </Button>
              <Button
                color="danger"
                onClick={handleClearList}
                disabled={tableData.length === 0}
              >
                Clear Data
              </Button>
            </div>
          )}
        </Col>
        {tableData?.length !== 0 && (
          <Col xl="12" className="">
            <div className="bg-white">
              <ReusableTable
                tableData={tableData || []}
                tableKey={csvKeys || []}
                tableScrollClass={"tablescrollMain table-bordered"}
              />
            </div>
          </Col>
        )}
      </Row>

      {/* Modal for the step-by-step process */}
      <Modal isOpen={modalOpen} size="lg" centered>
        <ModalHeader toggle={closeModal}>
          Step {step}:{" "}
          {step === 2
            ? "Import Numbers"
            : step === 3
            ? "Add Country Code"
            : "Review Duplicates"}
        </ModalHeader>
        <ModalBody>{renderCurrentStep()}</ModalBody>
        <ModalFooter>
          {step > 2 && (
            <Button color="secondary" onClick={handlePrevStep}>
              Previous
            </Button>
          )}
          {isNextButtonVisible && step < 4 && (
            <Button
              color="primary"
              onClick={handleNextStep}
              disabled={isNextButtonDisabled}
            >
              Next
            </Button>
          )}

          {step === 4 && (
            <Button
              color="primary"
              onClick={() => {

                let uniqueData = [...new Map(finalTableData.map(item => [item.number, item])).values()];
                const finalData = isAllowDuplicate
                  ? finalTableData // Include both unique and duplicate entries
                  : uniqueData; // Only include unique entries

                  // console.log("finalTableData : ", finalTableData);
                  // console.log("originalTableData : ", originalTableData);
                  // console.log("duplicateTableData : ", duplicateTableData);
                  // console.log("uniqueData : ", uniqueData);
                toggleModal();
                setTableData(finalData);
                setCsvKeys(tableKeys);
              }}
            >
              Import
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default InsertCsv;
